import React, { useEffect, useState } from 'react';
import {
    useNavigate,
    useLocation,
    useMatch,
} from 'react-router-dom';
import { RenderRoutes } from './Routes/Routes';
import ModalConfirm from './Components/ModalConfirm';
import AppTopBar from './Components/AppTopBar';
import AppDrawer from './Components/AppDrawer';
import { useData } from './Context/Context';
import Controls from './Components/Controls/Controls';
import { useForm, Form } from './Components/UseForm';
import ModalNotifications from './Components/ModalNotifications';
import Modal from './Components/Modal';
import API from './libs/ServerAPIs';
import './App.css';
import { useSnackbar } from 'notistack';
import ScrollToTop from './Components/ScrollToTop';
import ModalPreferences from './Components/ModalPreferences/ModalPreferences';
import ModalCreateLogbook from './Components/ModalCreateLogbook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalAddUser from './Pages/AdminAdvanceView/components/modal-add-user';
import { Box, Button, Grid, Slide, Typography, Container } from '@mui/material';
import IdleTimerContainer from './Components/IdleTimerContainer';
import { removeImpersonatePrefs } from './libs/ImpersonatePrefs';
import { saveImpersonatePrefs } from './libs/ImpersonatePrefs';

function App(props) {
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/
    let match = useMatch({ path: '/sighting/:locationId', end: false });
    let match_service_binder = useMatch({
        path: '/service-binder/:sId',
        end: false,
    });
    let locationId = match?.params.locationId;
    let sbinderId = match_service_binder?.params.sId;
    let matchID = useMatch({ path: '/client/:id', end: false });
    let clientId = matchID?.params.id;
    let token = null;
    let isadvancestatus = false;
    const windows = props;
    const location = useLocation();
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const container =
        windows?.window !== undefined
            ? () => windows?.window().document.body
            : undefined;
    const drawerWidth = 240;
    const {
        frame,
        auth,
        activeSession,
        userData,
        selectedBranch,
        checkAuth,
        drawerOpen,
        primaryUser,
        loading_data,
        InnerImpersonate,
        globalIsDisabled,
    } = useData(useData);
    const [isAuth, setIsAuth] = auth;
    const [isInnerPageImpersonate] = InnerImpersonate;
    const [primary_user, setPrimaryUser] = primaryUser;
    const [, setIsActiveSession] = activeSession;
    const [var_visibleFrame, set_visibleFrame] = frame;
    const [var_drawer_open, set_drawer_open] = drawerOpen;
    const [user_details, setUserDetails] = userData;
    const [, setSelectedBranch] = selectedBranch;
    const [, set_loading_data] = loading_data;
    const [, set_global_isDisabled] = globalIsDisabled;
    const [var_mdl_changePassword_open, set_mdl_changePassword_open] =
        useState(false);
    const [var_mdl_notifications_open, set_mdl_notifications_open] =
        useState(false);
    const [var_mdl_preferences_open, set_mdl_preferences_open] =
        useState(false);
    const [var_mdl_create_logbook_open, set_mdl_create_logbook_open] =
        useState(false);
    const [isServiceBinder, setIsServiceBinder] = useState(false);
    const [var_mdl_pest_sight, set_mdl_pest_sight] = useState(false);
    const [var_loading, set_loading] = useState(false);
    const [var_first_loading, set_first_loading] = useState(true);
    const [isMobile, set_isMobile] = useState(window.innerWidth <= 768);
    const [var_location_id, set_location_id] = useState('');
    const [var_pest_sight_view_text, set_pest_sight_view_text] = useState('');
    const [var_pest_sight_view_type, set_pest_sight_view_type] = useState(false);
    const [var_mdl_advView_open, set_mdl_advView_open] = useState(false);
    const params_token = new URLSearchParams(location.search);
    const initValues = {
        textMessages: false,
        pushNotifications: true,
        phoneCalls: false,
        currentPassword: '******',
        location: 'all',
    };
    const { var_values, var_errors, set_errors, handleInputChange } =
        useForm(initValues);
    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768) {
            set_isMobile(true);
        } else {
            set_isMobile(false);
        }
    };

    const handleStorageChange = (event) => {
        if (event.key === 'loginWithToken') {
            console.log("loginWithToken::Old value::", event.oldValue); // Old value of the key
            console.log("loginWithToken::New value::", event.newValue); // New value of the key
            const locationCode = event.newValue;
            if (locationCode !== '') {
                localStorage.setItem('isCustomerPortalRequest', true);
                localStorage.removeItem('loginWithCustomerPortal');
                window.location.href = '/';
            }
        }
        else if (event.key === 'loginWithCustomerPortal') {
            console.log("loginWithCustomerPortal::Old value::", event.oldValue); // Old value of the key
            console.log("loginWithCustomerPortal::New value::", event.newValue); // New value of the key
            const friendlyUrl = event.newValue;
            if (friendlyUrl !== '') {
                localStorage.setItem('isCustomerPortalRequest', true);
                localStorage.removeItem('loginWithToken');
                window.location.href = `/service-binder/${friendlyUrl}`;
            }
        }
    };

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        // Add event listener for storage changes
        window.addEventListener('storage', handleStorageChange);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (
            location.pathname.includes('admin') === true &&
            location.pathname.includes('admin/edit-logbook') === false &&
            user_details?.isImpersonate
        ) {
            goBackFromImpersonate();
        }
        if (!isMobile && !isInnerPageImpersonate) {
            window.addEventListener('popstate', (event) => {
                if (primary_user) {
                    removeImpersonatePrefs();
                    if (location.pathname.includes('admin') !== true) {
                        history('/admin');
                        //user_details?.isServiceBinder ? history('/') : history('/');
                    }
                    setUserDetails({ ...primary_user });
                }
            });
        }
        if (
            (!localStorage.getItem('isAdmin') &&
                location.pathname?.includes('/service-binder') &&
                var_first_loading) ||
            (params_token.has('token') && var_first_loading)
        ) {
            checkIfServiceBinder();
            set_first_loading(false);
        }
        async function checkSession() {
            if (
                (localStorage.getItem('isAdmin') &&
                    !params_token.has('token')) ||
                ![
                    `/service-binder/${sbinderId}`,
                    `/sighting/${locationId}`,
                    '/:id',
                    '/register',
                    '/recover-password',
                    '/reset-password',
                    '/link-expired',
                ].includes(location.pathname)
            ) {
                //set_loading_data(true)
                var userInfo = await checkAuth();
                //set_loading_data(false)
                if (!userInfo.error) {
                    if (localStorage.getItem('isAdmin')) {
                        const requestUrl = `/api/v1/location/${localStorage.getItem(
                            'libs_id'
                        )}`;
                        await API.get(requestUrl)
                            .then((location) => {
                                if (!location.error) {
                                    setPrimaryUser(userInfo);
                                    sessionStorage.setItem(
                                        'isImpersonate',
                                        true
                                    );
                                    sessionStorage.setItem(
                                        'isServiceBinder',
                                        location?.isServiceBinder
                                    );
                                    sessionStorage.setItem(
                                        'primaryUser',
                                        userInfo?.role
                                    );
                                    sessionStorage.setItem(
                                        'impersonatingRole',
                                        'Customer'
                                    );
                                    sessionStorage.setItem(
                                        'locationCode',
                                        location?.code
                                    );
                                    sessionStorage.setItem(
                                        'impersonatedLocationID',
                                        location?.locationID
                                    );
                                    userInfo = {
                                        ...userInfo,
                                        role: 'Customer',
                                        isImpersonate: true,
                                        isAdmin: false,
                                        isCustomer: location.isServiceBinder
                                            ? false
                                            : true,
                                        isServiceBinder:
                                            location.isServiceBinder,
                                        userName: location?.code,
                                        location,
                                    };
                                    if (
                                        location.pathname?.contains(
                                            'admin/edit-logbook'
                                        )
                                    ) {
                                        goToEditLogbook();
                                    }
                                }
                            })
                            .catch((error) => {
                                // console.log('Error:', error);
                                return { error: true };
                            });
                        setUserDetails(userInfo);
                    } else {
                        if (
                            userInfo?.location?.isAdvanceViewActive &&
                            !location.pathname?.includes('/service-binder/')
                        ) {
                            history('/404', { replace: true });
                        } else {
                            validatePath(location.pathname, userInfo);
                            setIsAuth(true);
                            setIsActiveSession(true);
                            setUserDetails(userInfo);
                            set_visibleFrame(true);
                        }
                    }
                } else {
                    checkUserAutorize(userInfo.message);
                    if (user_details) {
                        showSnackbar('error', 'Your session has expired.');
                        history('/', { replace: true });
                    }
                    setIsAuth(false);
                    setUserDetails(null);
                    setSelectedBranch(null);
                    localStorage.clear();
                }
            }
        }
        if (!user_details) checkSession();
        else if (
            user_details &&
            user_details.token &&
            user_details.isadvancestatus
        ) {
            setTimeout(() => {
                checkIfServiceBinder();
            }, '60000');
        }
    }, [
        isAuth,
        user_details,
        location.pathname,
        isMobile,
        isInnerPageImpersonate,
    ]);

    useEffect(() => {
        var locID = localStorage.getItem('libs_id');
        if (
            (location.pathname?.includes('admin/edit-logbook') ||
                location.pathname?.includes('client')) &&
            user_details?.isAdmin
        ) {
            checktabsession();
        } else if (
            location.pathname?.includes('client') &&
            (user_details?.isImpersonate ||
                sessionStorage.getItem('isImpersonate') === 'true') &&
            locID !== clientId
        ) {
            gotoClientImp(clientId);
        }
    }, [location.pathname, user_details]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function checkUserAutorize(message) {
        if (
            message === 'Unauthorized user' ||
            message === 'No token provided'
        ) {
            //var url_encode = location.pathname;
            const params = new URLSearchParams(location.search);
            // history.replace(`/?ref=${encodeURIComponent(url_encode + `?${params.toString()}`)}`)
            if (params.toString().includes('sighting-ref')) {
                let searchParams = new URLSearchParams();
                searchParams.set('search', params.toString);
                // set_search_params(searchParams)
                // history('/404',{replace:true});
                // history.replace({ search: params.toString() });
            } else if (params.toString().includes('alert')) {
                let searchParams = new URLSearchParams();
                searchParams.set('search', params.toString);
                // set_search_params(searchParams)
            } else {
                history('/', { replace: true });
            }
        }
    }

    async function checkIfServiceBinder() {
        const params = new URLSearchParams(location.search);
        if (
            location.pathname.includes('/service-binder') &&
            !location.pathname.includes('/admin/service-binder')
        ) {
            if (sbinderId) {
                await checkAuthSession(sbinderId);
                set_first_loading(false);
            } else {
                let userInfo = await checkAuth();
                set_loading_data(false);
                set_first_loading(false);
                if (!userInfo.error) {
                    if (!userInfo.isServiceBinder) {
                        const { userName, isLocation } = userInfo;
                        validatePath(location.pathname, userInfo);
                        setIsAuth(true);
                        setIsActiveSession(true);
                        setUserDetails(userInfo);
                        set_visibleFrame(true);
                        if (isLocation) {
                            // history.replace('' + userName);
                            history('/client/' + userName, { replace: true });
                        } else if (userInfo.isAdmin) {
                            history('/admin/servicebinders', { replace: true });
                        }
                    } else if (userInfo.isServiceBinder) {
                        set_loading_data(false);
                        set_first_loading(false);
                        if (params.get('token')) {
                            let token = params.has('token')
                                ? params.get('token')
                                : user_details?.token;
                            if (params.has('token')) {
                                removeImpersonatePrefs();
                            }
                            const data = await API.get(
                                `/api/v1/service-binder/${sbinderId}/advanceViewTokeValidation/${token}`
                            );
                            if (!data.error) {
                                set_visibleFrame(true);
                                validatePath(location.pathname, userInfo);
                                setIsAuth(true);
                                setIsActiveSession(true);
                                await checkAuthSession(sbinderId);
                            } else {
                                setIsAuth(false);
                                showSnackbar(
                                    'error',
                                    'You provided an invalid token or it has been expired.'
                                );
                                history(
                                    `/service-binder/${sbinderId}/link-expired`,
                                    { replace: true }
                                );
                            }
                        } else {
                            await checkAuthSession(sbinderId);
                        }
                    } else if (history.location.pathname !== '/') {
                        await checkAuthSession(sbinderId);
                    }
                } else if (history.location.pathname !== '/') {
                    await checkAuthSession(sbinderId);
                } else {
                    // console.log('end 3');
                }
            }
        }
    }

    async function checkAuthSession(sbinderId) {
        if (sbinderId)
            API.get(`/api/v1/auth/sb-login/${sbinderId}`)
                .then(async (res) => {
                    if (res.success) {
                        if (res.impersonate) {
                            const _location = res.data.location;
                            set_global_isDisabled(true);
                            saveImpersonatePrefs(_location.code);
                            setPrimaryUser(res.data.activeUser);
                            setUserDetails({
                                ...res.data.activeUser,
                                role: 'Customer',
                                isAdmin: false,
                                isCustomer: true,
                                isServiceBinder: true,
                                isImpersonate: true,
                                userName: _location?.code,
                                location: _location,
                            });
                            history(`/service-binder/${_location.friendlyURL}`);
                            set_global_isDisabled(false);
                        } else {
                            localStorage.setItem('tokenDoc', res.token);
                            sessionStorage.setItem('tokenSession', res.token);
                            const params = new URLSearchParams(location.search);
                            set_loading_data(false);
                            if (
                                params.has('token') ||
                                (user_details && user_details.token)
                            ) {
                                removeImpersonatePrefs();
                                token =
                                    params.get('token') || user_details.token;
                                const data = await API.get(
                                    `/api/v1/service-binder/${sbinderId}/advanceViewTokeValidation/${params.has('token')
                                        ? params.get('token')
                                        : user_details && user_details.token
                                    }`
                                );
                                if (!data.error) {
                                    isadvancestatus = true;
                                } else {
                                    isadvancestatus = false;
                                    showSnackbar(
                                        'error',
                                        'You provided an invalid token or it has been expired.'
                                    );
                                    history(
                                        `/service-binder/${sbinderId}/link-expired`,
                                        { replace: true }
                                    );
                                }
                            }
                            let userDataVals = await checkAuth();
                            userDataVals.isadvancestatus = isadvancestatus;
                            userDataVals.token = token;
                            setUserDetails(userDataVals);
                            set_visibleFrame(true);
                            setIsServiceBinder(userDataVals.isServiceBinder);
                            set_first_loading(false);
                        }
                    } else {
                        history('/', { replace: true });
                    }
                })
                .catch((error) => {
                    // console.log('error:::', error);
                });
    }

    function validatePath(path, user_details) { }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function goBackFromImpersonate() {
        removeImpersonatePrefs();
        setUserDetails(null);
        user_details?.isServiceBinder
            ? history('/admin/servicebinders')
            : history('/admin/logbooks');
        var userinfo = await checkAuth();
        if (userinfo) {
            setUserDetails(userinfo);
        } else {
            setUserDetails({ ...primary_user });
        }
    }

    function checktabsession() {
        if (
            localStorage.getItem('isAdmin') === null &&
            !(params_token.has('impersonate') && user_details?.isAdmin)
        ) {
            user_details?.isServiceBinder
                ? history('/admin/servicebinders')
                : history('/admin/logbooks');
        } else if (params_token.has('impersonate')) {
            gotoClientImp(clientId);
        }
    }

    async function gotoClientImp(location_id) {
        setPrimaryUser(user_details);
        set_global_isDisabled(true);
        saveImpersonatePrefs(location_id);
        const requestUrl = `/api/v1/location/${location_id}`;
        await API.get(requestUrl)
            .then((location) => {
                if (!location.error) {
                    setUserDetails({
                        ...user_details,
                        role: 'Customer',
                        isImpersonate: true,
                        isAdmin: false,
                        isCustomer: true,
                        userName: location?.code,
                        location,
                    });
                    // history.replace('/client/');
                    history('/client/' + location?.code, { replace: true });
                    set_global_isDisabled(false);
                } else {
                    set_global_isDisabled(false);
                }
            })
            .catch((error) => {
                // console.log("Error:", error);
                set_global_isDisabled(false);
                return { error: true };
            });
    }

    function goToEditLogbook() {
        history(
            `/admin/edit-logbook/${clientId ? clientId : user_details.location?.locationID
            }`
        );
    }

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    function handlePestSightingView() {
        set_loading(true);
        API.put(`/api/v1/sb/${var_location_id}/setPestSightingActivation`, {
            isPestSightingActive: var_pest_sight_view_type,
        })
            .then((response) => {
                if (!response.error) {
                    let userInfoTemp = { ...user_details };
                    const { location } = userInfoTemp;
                    userInfoTemp.location.isPestSightingActive =
                        location.isPestSightingActive =
                        var_pest_sight_view_type;
                    setUserDetails(userInfoTemp);
                    closeMdlPestSightingView();
                }
                set_loading(false);
                showSnackbar(
                    response.error ? 'error' : 'success',
                    response.message
                );
            })
            .catch((error) => {
                return { error: true };
            });
    }

    function handleDrawerToggle() {
        set_drawer_open(!var_drawer_open);
    }

    function openMdlPreferences() {
        if (!user_details?.isImpersonate && user_details?.isCustomer) {
            set_mdl_preferences_open(false);
        } else {
            set_mdl_preferences_open(true);
        }
    }

    function closeMdlPreferences() {
        set_mdl_preferences_open(false);
    }

    function closeMdlChangePassword() {
        set_mdl_changePassword_open(false);
    }

    function openMdlNotifications() {
        set_mdl_notifications_open(true);
    }

    function closeMdlNotifications() {
        set_mdl_notifications_open(false);
    }

    function openMdlCreateLogbook() {
        set_mdl_create_logbook_open(true);
    }

    function closeMdlCreateLogbook() {
        set_mdl_create_logbook_open(false);
    }

    function handlePasswordUpdate(e) {
        let field_errors = {};
        const no_match_msg = 'Passwords do not match';

        ['oldPassword', 'newPassword', 'confirmNewPassword'].forEach(
            (field) => {
                if (field in var_values && var_values[field].trim() === '') {
                    field_errors = {
                        ...field_errors,
                        [field]: `This field must be provided with some value`,
                    };
                }

                if (
                    field === 'newPassword' &&
                    var_values.newPassword !== var_values.confirmNewPassword
                ) {
                    field_errors = {
                        ...field_errors,
                        newPassword: no_match_msg,
                        cNewPassword: no_match_msg,
                    };
                }
            }
        );

        set_errors(field_errors);
        // if no errors then send update password request
        if (Object.keys(field_errors).length === 0) {
            API.post('/api/v1/user/change-password', {
                userId: userData && userData.id,
                currentPassword: var_values.oldPassword,
                newPassword: var_values.newPassword,
            }).then((response) => {
                showSnackbar(
                    !response.error ? 'success' : 'error',
                    response.message
                );
                if (!response.error) {
                    //resetForm();
                    closeMdlChangePassword();
                }
            });
        }
    }

    function openMdlAdvView() {
        set_mdl_advView_open(true);
    }

    function closeMdlAdvView() {
        set_mdl_advView_open(false);
    }

    function openMdlPestSightingView() {
        set_location_id(user_details?.location?.locationID);
        set_pest_sight_view_type(!user_details?.location?.isPestSightingActive);
        set_pest_sight_view_text(
            user_details?.location?.isPestSightingActive
                ? 'Are you sure want to de-activate pest sightings'
                : 'Are you sure want to activate pest sightings'
        );
        set_mdl_pest_sight(true);
    }

    function closeMdlPestSightingView() {
        set_mdl_pest_sight(false);
    }
    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            {var_visibleFrame ? (
                <>
                    {isAuth && <IdleTimerContainer />}
                    <Box
                        className="ScpApp"
                        sx={{
                            pt: { xs: '56px', sm: '64px' },
                            pb: '36px',
                            boxSizing: 'border-box',
                            minHeight: '100vh',
                            position: 'relative',
                        }}
                    >
                        {user_details && (
                            <AppTopBar
                                isImpersonate={user_details?.isImpersonate}
                                drawerToggle={handleDrawerToggle}
                                openMdlNotifications={openMdlNotifications}
                                closeMdlNotifications={closeMdlNotifications}
                                openMdlPreferences={openMdlPreferences}
                                openMdlCreateLogbook={openMdlCreateLogbook}
                                openMdlAdvView={openMdlAdvView}
                            />
                        )}

                        <Box sx={{ display: 'flex' }}>
                            {user_details && (
                                <AppDrawer
                                    container={container}
                                    drawerWidth={drawerWidth}
                                    open={var_drawer_open}
                                    drawerToggle={handleDrawerToggle}
                                    isServiceBinder={isServiceBinder}
                                    setUserDetails={setUserDetails}
                                />
                            )}
                            <Box
                                component="main"
                                sx={{ flexGrow: 1, minWidth: '0' }}
                            >
                                <ScrollToTop>
                                    {user_details?.isImpersonate &&
                                        !location.pathname.includes('admin') &&
                                        !user_details?.isServiceBinder &&
                                        !isMobile && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    {primary_user && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    <ArrowBackIcon />
                                                                }
                                                                onClick={
                                                                    goBackFromImpersonate
                                                                }
                                                            >
                                                                Back to{' '}
                                                                {user_details?.isServiceBinder
                                                                    ? 'Service Binder List'
                                                                    : 'Logbooks'}
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {!user_details?.isFieldUser && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                ml: 'auto',
                                                                alignItems:
                                                                    'center',
                                                                '& > *': {
                                                                    ml: '16px !important',
                                                                },
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                startIcon={
                                                                    <EditIcon />
                                                                }
                                                                onClick={
                                                                    goToEditLogbook
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                    {!location.pathname.includes('admin') &&
                                        user_details?.isServiceBinder &&
                                        primary_user &&
                                        !user_details?.isadvancestatus &&
                                        !isMobile && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Button
                                                            startIcon={
                                                                <ArrowBackIcon />
                                                            }
                                                            onClick={
                                                                goBackFromImpersonate
                                                            }
                                                        >
                                                            Back to{' '}
                                                            {user_details?.isServiceBinder
                                                                ? 'Service Binder List'
                                                                : 'Logbooks'}
                                                        </Button>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            ml: 'auto',
                                                            alignItems:
                                                                'center',
                                                            '& > *': {
                                                                ml: '16px !important',
                                                            },
                                                        }}
                                                    >
                                                        {!user_details?.isFieldUser && (
                                                            <Button
                                                                variant="contained"
                                                                startIcon={
                                                                    <EditIcon />
                                                                }
                                                                onClick={
                                                                    goToEditLogbook
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        )}
                                                        {!user_details?.isFieldUser &&
                                                            user_details?.isServiceBinder && (
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{
                                                                        ml: '8px',
                                                                    }}
                                                                    onClick={
                                                                        openMdlPestSightingView
                                                                    }
                                                                >
                                                                    {user_details
                                                                        ?.location
                                                                        ?.isPestSightingActive
                                                                        ? 'De-activate pest sightings'
                                                                        : 'Activate pest sightings'}
                                                                </Button>
                                                            )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    {location.pathname.includes(
                                        'admin/edit-logbook/'
                                    ) &&
                                        user_details?.isServiceBinder &&
                                        !isMobile && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    {primary_user && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    <ArrowBackIcon />
                                                                }
                                                                onClick={
                                                                    goBackFromImpersonate
                                                                }
                                                            >
                                                                Back to{' '}
                                                                {user_details?.isServiceBinder
                                                                    ? 'Service Binder List'
                                                                    : 'Logbooks'}
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            ml: 'auto',
                                                            alignItems:
                                                                'center',
                                                            '& > *': {
                                                                ml: '16px !important',
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            startIcon={
                                                                <VisibilityIcon />
                                                            }
                                                            onClick={() => {
                                                                history(
                                                                    `/service-binder/${user_details?.location?.friendlyURL}`,
                                                                    {
                                                                        replace: true,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}

                                    {/* Mobile Back */}
                                    {user_details?.isImpersonate &&
                                        !location.pathname.includes('admin') &&
                                        !user_details?.isServiceBinder &&
                                        isMobile &&
                                        !isInnerPageImpersonate && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    {primary_user && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    <ArrowBackIcon />
                                                                }
                                                                onClick={
                                                                    goBackFromImpersonate
                                                                }
                                                            >
                                                                Back to{' '}
                                                                {user_details?.isServiceBinder
                                                                    ? 'Service Binder List'
                                                                    : 'Logbooks'}
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {!user_details?.isFieldUser && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                ml: 'auto',
                                                                alignItems:
                                                                    'center',
                                                                '& > *': {
                                                                    ml: '16px !important',
                                                                },
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                startIcon={
                                                                    <EditIcon />
                                                                }
                                                                onClick={
                                                                    goToEditLogbook
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                    {!location.pathname.includes('admin') &&
                                        user_details?.isServiceBinder &&
                                        primary_user &&
                                        !user_details?.isadvancestatus &&
                                        isMobile &&
                                        !isInnerPageImpersonate && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Button
                                                            startIcon={
                                                                <ArrowBackIcon />
                                                            }
                                                            onClick={
                                                                goBackFromImpersonate
                                                            }
                                                        >
                                                            Back to{' '}
                                                            {user_details?.isServiceBinder
                                                                ? 'Service Binder List'
                                                                : 'Logbooks'}
                                                        </Button>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            ml: 'auto',
                                                            alignItems:
                                                                'center',
                                                            '& > *': {
                                                                ml: '16px !important',
                                                            },
                                                        }}
                                                    >
                                                        {!user_details?.isFieldUser && (
                                                            <Button
                                                                variant="contained"
                                                                startIcon={
                                                                    <EditIcon />
                                                                }
                                                                onClick={
                                                                    goToEditLogbook
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        )}
                                                        {user_details?.isServiceBinder && (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    ml: '8px',
                                                                }}
                                                                onClick={
                                                                    openMdlPestSightingView
                                                                }
                                                            >
                                                                {user_details
                                                                    ?.location
                                                                    ?.isPestSightingActive
                                                                    ? 'De-activate pest sightings'
                                                                    : 'Activate pest sightings'}
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    {location.pathname.includes(
                                        'admin/edit-logbook/'
                                    ) &&
                                        user_details?.isServiceBinder &&
                                        isMobile &&
                                        !isInnerPageImpersonate && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    {primary_user && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    <ArrowBackIcon />
                                                                }
                                                                onClick={
                                                                    goBackFromImpersonate
                                                                }
                                                            >
                                                                Back to{' '}
                                                                {user_details?.isServiceBinder
                                                                    ? 'Service Binder List'
                                                                    : 'Logbooks'}
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            ml: 'auto',
                                                            alignItems:
                                                                'center',
                                                            '& > *': {
                                                                ml: '16px !important',
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            startIcon={
                                                                <VisibilityIcon />
                                                            }
                                                            onClick={() => {
                                                                history(
                                                                    `/service-binder/${user_details?.location?.friendlyURL}`,
                                                                    {
                                                                        replace: true,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    {user_details?.isImpersonate &&
                                        isMobile &&
                                        isInnerPageImpersonate && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    padding: '0 24px',
                                                }}
                                                className="mobile-view-mt"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        minHeight: '69px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            id="back-icon"
                                                            startIcon={
                                                                <ArrowBackIcon />
                                                            }
                                                            onClick={
                                                                history.goBack
                                                            }
                                                        >
                                                            Back
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    <RenderRoutes />
                                </ScrollToTop>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                pl: { lg: '240px' },
                                textAlign: 'center',
                                height: '36px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Container maxWidth="xl">
                                <Box sx={{ position: 'relative' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        &copy; 2022 Sprague Pest Solutions
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: 'block',
                                            },
                                            position: { sm: 'absolute' },
                                            right: '0',
                                            top: '50%',
                                            transform: {
                                                sm: 'translateY(-50%)',
                                            },
                                            color: 'rgba(0,0,0,0.28)',
                                        }}
                                    >
                                        Design &amp; developed by Powersoft19
                                    </Typography>
                                </Box>
                            </Container>
                        </Box>
                    </Box>

                    {/***** MODAL: CREATE LOGBOOK ********************************************************/}
                    <ModalCreateLogbook
                        openModal={var_mdl_create_logbook_open}
                        closeModal={closeMdlCreateLogbook}
                    />
                    {/***** END MODAL: CREATE LOGBOOK ****************************************************/}

                    {/***** MODAL: PREFERENCES ********************************************************/}
                    <ModalPreferences
                        openModal={var_mdl_preferences_open}
                        closeModal={closeMdlPreferences}
                    />
                    {/***** END MODAL: PREFERENCES ****************************************************/}

                    {/***** MODAL: NOTIFICATIONS ********************************************************/}
                    <ModalNotifications
                        openModal={var_mdl_notifications_open}
                        closeModal={closeMdlNotifications}
                    />
                    {/***** END MODAL: NOTIFICATIONS ****************************************************/}

                    {/***** MODAL: CHANGE PASSWORD ******************************************************/}
                    <Modal
                        title="Change password"
                        openModal={var_mdl_changePassword_open}
                        closeModal={closeMdlChangePassword}
                        width="xs"
                        dialogActions={
                            <>
                                <Button onClick={closeMdlChangePassword}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handlePasswordUpdate}
                                >
                                    Save
                                </Button>
                            </>
                        }
                    >
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="oldPassword"
                                        label="Old password"
                                        value={var_values.oldPassword}
                                        error={var_errors.oldPassword}
                                        onChange={handleInputChange}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="newPassword"
                                        label="New password"
                                        value={var_values.newPassword}
                                        error={var_errors.newPassword}
                                        onChange={handleInputChange}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="confirmNewPassword"
                                        label="Confirm new password"
                                        value={var_values.confirmNewPassword}
                                        error={var_errors.confirmNewPassword}
                                        onChange={handleInputChange}
                                        type="password"
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </Modal>
                    {/***** END MODAL: CHANGE PASSWORD ****************************************************/}
                    {/***** MODAL: ENABLE PEST SIGHTING *******************************************************/}
                    <ModalConfirm
                        title={'Pest Sighting Log'}
                        text={var_pest_sight_view_text}
                        type={'warning'}
                        openModal={var_mdl_pest_sight}
                        closeModal={closeMdlPestSightingView}
                        handleConfirm={handlePestSightingView}
                        loading={var_loading}
                    />
                    {/***** END MODAL: DELETE SIGHT ***************************************************/}

                    {/***** MODAL: ADD USER ***********************************************************/}
                    <ModalAddUser
                        openModal={var_mdl_advView_open}
                        title={'Request For Advance View'}
                        // set_refresh_data={set_refresh_data}
                        // var_refresh_data={var_refresh_data}
                        closeModal={closeMdlAdvView}
                    />
                    {/***** END MODAL: ADD USER *******************************************************/}
                </>
            ) : (
                <>
                    <div className="app-full-frame">
                        <RenderRoutes />
                    </div>
                </>
            )}
        </>
    );
}
export default App;
